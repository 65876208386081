export const itemVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

export const standardizedSize = {
  width: "350px",
  height: "600px",
};
