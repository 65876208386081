import React, { useState, useEffect } from "react";
import { itemVariants } from "../SharedConsts";
import DDD from "../assets/ddd.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./PhoneInput.css";
import { addNewUser, checkUserExists } from "../service/formApi"; // adjust the import path as needed
import { toast, ToastContainer } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { Tilt } from "react-tilt";

const formVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -50 },
};

const InputComponent = ({
  label,
  placeholder,
  type = "text",
  id,
  className,
  value,
  onChange,
  error,
  ...rest
}) => {
  return (
    <div className={`flex flex-col ${className}`}>
      {label && (
        <label htmlFor={id} className="mb-2">
          {label}
        </label>
      )}
      {type === "textarea" ? (
        <textarea
          id={id}
          placeholder={placeholder}
          className={`border ${
            error ? "border-red-500" : "border-black"
          } p-2 focus:border-blue-500 focus:outline-none h-18`}
          value={value}
          onChange={onChange}
          {...rest}
        />
      ) : (
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          className={`border ${
            error ? "border-red-500" : "border-black"
          } p-2 focus:border-blue-500 focus:outline-none`}
          value={value}
          onChange={onChange}
          {...rest}
        />
      )}
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

const SubmitForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    acceptTerms: false,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const hasSubmitted = Cookies.get("formSubmitted");
    if (hasSubmitted) {
      setIsSubmitted(true);
    }
  }, []);

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (!validateEmail(formData.email))
      newErrors.email = "Invalid email format";
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required";
    if (!formData.message.trim()) newErrors.message = "Message is required";
    if (!formData.acceptTerms)
      newErrors.acceptTerms = "You must accept the terms";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true);
      try {
        // Check if user already exists
        const userExists = await checkUserExists(
          formData.email,
          formData.phone
        );
        if (userExists) {
          toast.warn(
            "You have already submitted your details. We'll be in touch soon!"
          );
          setIsSubmitting(false);
          return;
        }

        const newUser = await addNewUser(formData);
        console.log("User added successfully", newUser);
        toast.success("Thank you for your submission!");
        Cookies.set("formSubmitted", "true", { expires: 30 }); // Cookie expires in 30 days
        setIsSubmitted(true);
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("An error occurred. Please try again later.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <motion.section
      className="flex w-full gap-20 flex-col-reverse lg:flex-row flex-wrap lg:flex-nowrap py-20"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={formVariants}
    >
      <div className="lg:flex-1">
        <AnimatePresence mode="wait">
          {!isSubmitted ? (
            <motion.form
              key="form"
              onSubmit={handleSubmit}
              className="text-base"
              variants={formVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <div className="mb-10">
                <h1 className="text-5xl font-bold mb-6">
                  Your Success Starts Here
                </h1>
                <p className="mb-6">
                  Share your vision with us, and let's bring it to life.
                </p>
              </div>
              <InputComponent
                label="Name"
                placeholder="Enter your name"
                id="name"
                className="mb-6"
                value={formData.name}
                onChange={handleInputChange}
                error={errors.name}
              />
              <InputComponent
                label="Email"
                placeholder="Enter your email"
                type="email"
                id="email"
                className="mb-6"
                value={formData.email}
                onChange={handleInputChange}
                error={errors.email}
              />
              <div className="flex flex-col mb-6">
                <label htmlFor="phone" className="mb-2">
                  Phone (WhatsApp)
                </label>
                <PhoneInput
                  country={"us"}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  inputProps={{
                    id: "phone",
                    required: true,
                  }}
                  containerClass="phone-input-container"
                  inputClass="form-control"
                  buttonClass="phone-input-button"
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
                )}
              </div>
              <InputComponent
                label="What would you like us to do for you?"
                placeholder="Describe your project or idea..."
                type="textarea"
                id="message"
                className="mb-6 "
                value={formData.message}
                onChange={handleInputChange}
                error={errors.message}
              />
              <div className="flex items-center mb-6">
                <input
                  type="checkbox"
                  id="acceptTerms"
                  className="mr-2"
                  checked={formData.acceptTerms}
                  onChange={handleInputChange}
                />
                <label htmlFor="acceptTerms">I accept the Terms</label>
              </div>
              {errors.acceptTerms && (
                <p className="text-red-500 text-sm mb-6">
                  {errors.acceptTerms}
                </p>
              )}
              <button
                type="submit"
                className="text-sm md:text-base bg-black text-white px-2 sm:px-4 md:px-6 py-2 rounded-md font-semibold hover:bg-white hover:text-black border border-black transition-colors"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{
                      duration: 1,
                      repeat: Infinity,
                      ease: "linear",
                    }}
                  >
                    ⟳
                  </motion.div>
                ) : (
                  "Submit"
                )}
              </button>
            </motion.form>
          ) : (
            <motion.div
              key="thankYou"
              variants={formVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="flex flex-col items-center justify-center h-full"
            >
              <h2 className="text-3xl font-bold mb-4">Thank You!</h2>
              <p className="text-xl mb-6">
                We've received your submission and will be in touch soon.
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <Tilt
        className="Tilt lg:flex-1"
        options={{
          max: 25,
          scale: 1.05,
          speed: 500,
          transition: true,
          easing: "cubic-bezier(.03,.98,.52,.99)",
          perspective: 1000,
        }}
      >
        <img className="object-cover h-full" alt="" src={DDD} />
      </Tilt>
      <div className="absolute">
        <ToastContainer position="bottom-right" />
      </div>
    </motion.section>
  );
};
export default SubmitForm;
