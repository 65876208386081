function Contact() {
  return (
    <div className="flex flex-col justify-start items-start overflow-hidden gap-20 py-20">
      <div className="flex flex-col justify-start items-start gap-4">
        <div className="flex flex-col justify-start items-start gap-6">
          <p className="text-5xl font-bold text-left">Contact us</p>
          <p className="text-lg text-left">
            Have any questions or concerns? Feel free to reach out to us.
          </p>
        </div>
      </div>
      <div className="flex justify-between h-full w-full gap-12 flex-col md:flex-row">
        <div className="flex flex-col justify-between h-full flex-1 gap-6 min-w-32">
          <svg
            width={48}
            height={48}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-12 h-12 relative"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M40 8H8C5.794 8 4 9.794 4 12V36C4 38.206 5.794 40 8 40H40C42.206 40 44 38.206 44 36V12C44 9.794 42.206 8 40 8ZM40 12V13.022L24 25.468L8 13.024V12H40ZM8 36V18.088L22.772 29.578C23.1222 29.8531 23.5547 30.0026 24 30.0026C24.4453 30.0026 24.8778 29.8531 25.228 29.578L40 18.088L40.004 36H8Z"
              fill="black"
            />
          </svg>
          <div className="flex flex-col justify-between items-start h-full gap-6">
            <div className="flex flex-col justify-start items-start h-full gap-4">
              <p className="text-[32px] font-bold text-left">Email</p>
              <p className="text-base text-left">
                Drop us an email and we'll get back to you.
              </p>
            </div>
            <a
              href="mailto:ivansolobear@gmail.com"
              className="text-base text-left"
            >
              ivansolobear@gmail.com
            </a>
          </div>
        </div>
        <div className="flex flex-col justify-between h-full flex-1 gap-6 min-w-32">
          <svg
            width={49}
            height={48}
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-12 h-12 relative"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M35.7475 24.5859C35.5619 24.4 35.3415 24.2525 35.0989 24.1519C34.8563 24.0513 34.5962 23.9995 34.3335 23.9995C34.0709 23.9995 33.8108 24.0513 33.5681 24.1519C33.3255 24.2525 33.1051 24.4 32.9195 24.5859L29.7315 27.7739C28.2535 27.3339 25.4955 26.3339 23.7475 24.5859C21.9995 22.8379 20.9995 20.0799 20.5595 18.6019L23.7475 15.4139C23.9334 15.2283 24.0809 15.0079 24.1815 14.7653C24.2821 14.5227 24.3339 14.2626 24.3339 13.9999C24.3339 13.7372 24.2821 13.4772 24.1815 13.2345C24.0809 12.9919 23.9334 12.7715 23.7475 12.5859L15.7475 4.58591C15.5619 4.40002 15.3415 4.25255 15.0989 4.15193C14.8563 4.0513 14.5962 3.99951 14.3335 3.99951C14.0709 3.99951 13.8108 4.0513 13.5681 4.15193C13.3255 4.25255 13.1051 4.40002 12.9195 4.58591L7.49552 10.0099C6.73552 10.7699 6.30752 11.8139 6.32352 12.8799C6.36952 15.7279 7.12352 25.6199 14.9195 33.4159C22.7155 41.2119 32.6075 41.9639 35.4575 42.0119H35.5135C36.5695 42.0119 37.5675 41.5959 38.3235 40.8399L43.7475 35.4159C43.9334 35.2303 44.0809 35.0099 44.1815 34.7673C44.2821 34.5247 44.3339 34.2646 44.3339 34.0019C44.3339 33.7392 44.2821 33.4792 44.1815 33.2365C44.0809 32.9939 43.9334 32.7735 43.7475 32.5879L35.7475 24.5859ZM35.4935 38.0099C32.9975 37.9679 24.4575 37.2979 17.7475 30.5859C11.0155 23.8539 10.3635 15.2839 10.3235 12.8379L14.3335 8.82791L19.5055 13.9999L16.9195 16.5859C16.6844 16.8208 16.5116 17.1106 16.4166 17.4291C16.3216 17.7475 16.3075 18.0846 16.3755 18.4099C16.4235 18.6399 17.5975 24.0939 20.9175 27.4139C24.2375 30.7339 29.6915 31.9079 29.9215 31.9559C30.2466 32.0258 30.5841 32.0128 30.9028 31.9181C31.2216 31.8234 31.5114 31.65 31.7455 31.4139L34.3335 28.8279L39.5055 33.9999L35.4935 38.0099Z"
              fill="black"
            />
          </svg>
          <div className="flex flex-col justify-start items-start gap-6">
            <div className="flex flex-col justify-start items-start gap-4">
              <p className="text-[32px] font-bold text-left">Phone</p>
              <p className="text-base text-left">
                Give us a call and let's discuss how we can help you.
              </p>
            </div>
            <a href="tel:+971-522-963-414" className="text-base text-left">
              +971-522-963-414
            </a>
          </div>
        </div>
        <div className="flex flex-col justify-between h-full flex-1 gap-6 min-w-32">
          <svg
            width={49}
            height={48}
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-12 h-12 relative"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M24.6668 28C29.0788 28 32.6668 24.412 32.6668 20C32.6668 15.588 29.0788 12 24.6668 12C20.2548 12 16.6668 15.588 16.6668 20C16.6668 24.412 20.2548 28 24.6668 28ZM24.6668 16C26.8728 16 28.6668 17.794 28.6668 20C28.6668 22.206 26.8728 24 24.6668 24C22.4608 24 20.6668 22.206 20.6668 20C20.6668 17.794 22.4608 16 24.6668 16ZM23.5069 43.628C23.8454 43.8697 24.2509 43.9997 24.6669 43.9997C25.0828 43.9997 25.4884 43.8697 25.8269 43.628C26.4349 43.198 40.7249 32.88 40.6669 20C40.6669 11.178 33.4889 4 24.6669 4C15.8449 4 8.66686 11.178 8.66686 19.99C8.60886 32.88 22.8989 43.198 23.5069 43.628ZM24.6669 8C31.2849 8 36.6669 13.382 36.6669 20.01C36.7089 28.886 27.8909 36.856 24.6669 39.47C21.4449 36.854 12.6249 28.882 12.6669 20C12.6669 13.382 18.0489 8 24.6669 8Z"
              fill="black"
            />
            <path
              d="M24.6668 28C29.0788 28 32.6668 24.412 32.6668 20C32.6668 15.588 29.0788 12 24.6668 12C20.2548 12 16.6668 15.588 16.6668 20C16.6668 24.412 20.2548 28 24.6668 28ZM24.6668 16C26.8728 16 28.6668 17.794 28.6668 20C28.6668 22.206 26.8728 24 24.6668 24C22.4608 24 20.6668 22.206 20.6668 20C20.6668 17.794 22.4608 16 24.6668 16ZM23.5069 43.628C23.8454 43.8697 24.2509 43.9997 24.6669 43.9997C25.0828 43.9997 25.4884 43.8697 25.8269 43.628C26.4349 43.198 40.7249 32.88 40.6669 20C40.6669 11.178 33.4889 4 24.6669 4C15.8449 4 8.66686 11.178 8.66686 19.99C8.60886 32.88 22.8989 43.198 23.5069 43.628ZM24.6669 8C31.2849 8 36.6669 13.382 36.6669 20.01C36.7089 28.886 27.8909 36.856 24.6669 39.47C21.4449 36.854 12.6249 28.882 12.6669 20C12.6669 13.382 18.0489 8 24.6669 8Z"
              fill="black"
            />
          </svg>
          <div className="flex flex-col justify-start items-start gap-6">
            <div className="flex flex-col justify-start items-start gap-4">
              <p className="text-[32px] font-bold text-left">Whatsapp</p>
              <p className="text-base text-left">
                Shoot us a message, it's the most convenient way.
              </p>
            </div>
            <a
              href="https://api.whatsapp.com/send?phone=972504871334"
              target="_blank"
              rel="noopener noreferrer"
              className="text-base text-left"
            >
              +972-50-487-1334
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
