import eee from "../assets/eee.jpeg";
import React from "react";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import { itemVariants } from "../SharedConsts";

function FounderSection() {
  return (
    <motion.div
      className="text-center flex gap-20 flex-wrap lg:flex-nowrap"
      initial="hidden"
      whileInView="visible"
      exit="exit"
      viewport={{ once: false, amount: 0.1 }}
      variants={itemVariants}
      transition={{ duration: 1 }}
    >
      <Tilt
        className="Tilt lg:flex-1"
        options={{
          max: 25,
          scale: 1.05,
          speed: 500,
          transition: true,
          easing: "cubic-bezier(.03,.98,.52,.99)",
          perspective: 1000,
        }}
      >
        <img src={eee} alt="Founder" className="w-auto h-full object-cover" />
      </Tilt>
      <div className="text-start mx-auto flex flex-col flex-1 justify-around">
        <h1 className="tr-3xl font-bold mb-5">A word from the founder</h1>
        <p className="tr-sm text-justify">
          Hi there! I'm so happy to meet you. Let me introduce myself, My name
          is Ivan and I'm a big dreamer just like you!
          <br />
          <br />
          Ever since I was a boy I dreamed of making the world a better place. I
          know it sounds cheesy, but I truly believe it is a worthy dream of
          chasing. I'd love to hear about your story, whatever its success or
          failure I'm sure you have some passion behind it. People like you
          inspire me! Stories of entrepreneurs who are chasing their dreams
          against all odds.
          <br />
          <br />
          I wish you all the best in your business venture. I'm confident that
          you'll be successful, because you have the drive, the passion, and the
          determination to make it happen. I'm always happy to share my
          knowledge and expertise with fellow dreamers, so please don't hesitate
          to reach out to me if you have any questions or need any advice.
          <br />
          <br />
          I'm excited to learn more about your business and how I can help you
          achieve your goals. I believe that by working together, we can create
          something truly amazing.
          <br />
          <br />
          "Collaboration is the key to innovation." - Mark Zuckerberg
        </p>
      </div>
    </motion.div>
  );
}

export default FounderSection;
