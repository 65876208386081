import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import { itemVariants } from "../SharedConsts";

function PortfolioItem({
  image,
  logo,
  title,
  description,
  buttonColor,
  buttonLink,
  isColourBright,
}) {
  return (
    <motion.div
      className="text-center flex gap-20 flex-wrap lg:flex-nowrap"
      initial="hidden"
      whileInView="visible"
      exit="exit"
      viewport={{ once: false, amount: 0.1 }}
      variants={itemVariants}
      transition={{ duration: 1 }}
    >
      <Tilt
        className="Tilt lg:flex-1"
        options={{
          max: 25,
          scale: 1.05,
          speed: 500,
          transition: true,
          easing: "cubic-bezier(.03,.98,.52,.99)",
          perspective: 1000,
        }}
      >
        <img src={image} alt={title} className="h-full object-cover" />
      </Tilt>
      <div className="flex lg:flex-1 items-start  text-justify mx-auto gap-5 justify-between tr-base flex-col ">
        <div className="flex items-center justify-center gap-10">
          <img src={logo} alt={title} className="h-28 w-28" />
          <h1 className="tr-3xl font-bold">{title}</h1>
        </div>
        {description}
        <button
          onClick={() => {
            window.open(buttonLink, "_blank");
          }}
          className={`${buttonColor} text-${
            isColourBright ? "black" : "white"
          } px-6 py-2 rounded-md font-semibold hover:bg-${
            isColourBright ? "white" : "black"
          } hover:text-${
            isColourBright ? "black" : "white"
          } border border-${buttonColor} transition-colors`}
        >
          Go to {title}
        </button>
      </div>
    </motion.div>
  );
}

export default PortfolioItem;
