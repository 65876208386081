import "./app.css";
import abc1 from "./assets/abc1.png";
import abc2 from "./assets/abc2.png";
import abc3 from "./assets/abc3.png";
import abc4 from "./assets/abc4.png";
import abc5 from "./assets/abc5.png";
import abc6 from "./assets/abc6.png";
import bbb1 from "./assets/bbb1.png";
import bbb2 from "./assets/bbb2.png";
import bbb3 from "./assets/bbb3.png";
import ccc1 from "./assets/ccc1.png";
import ccc2 from "./assets/ccc2.png";
import ccc3 from "./assets/ccc3.png";
import vvv1 from "./assets/vvv1.jpeg";
import vvv2 from "./assets/vvv2.jpeg";
import vvv3 from "./assets/vvv3.jpeg";
import logo from "./assets/logo.png";

import React, { useRef, useState, useEffect } from "react";

import SubmitForm from "./components/SubmitForm.js";
import Contact from "./components/Contact.js";
import HeroSection from "./components/HeroSection.js";
import ServiceItem from "./components/ServiceItem.js";
import FounderSection from "./components/FounderSection.js";
import PortfolioItem from "./components/PortfolioItem.js";

function App() {
  const submitFormRef = useRef(null);
  const servicesSectionRef = useRef(null);

  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const imageUrls = [
      abc1,
      abc2,
      abc3,
      abc4,
      abc5,
      abc6,
      bbb1,
      bbb2,
      bbb3,
      ccc1,
      ccc2,
      ccc3,
      vvv1,
      vvv2,
      vvv3,
      logo,
    ];

    let loadedCount = 0;
    const totalImages = imageUrls.length;

    const loadImage = (url) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = img.onerror = () => {
          loadedCount++;
          if (loadedCount === totalImages) {
            setImagesLoaded(true);
          }
          resolve();
        };
      });
    };
    Promise.all(imageUrls.map(loadImage))
      .then(() => setImagesLoaded(true))
      .catch((err) => console.error("Failed to load images", err));

    // Fallback: Set imagesLoaded to true after 5 seconds if not all images load
    const timeout = setTimeout(() => {
      setImagesLoaded(true);
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="App text-xl">
      <HeroSection
        onContactUsClick={() =>
          submitFormRef.current.scrollIntoView({ behavior: "smooth" })
        }
        onLearnMoreClick={() =>
          servicesSectionRef.current.scrollIntoView({ behavior: "smooth" })
        }
        imagesLoaded={imagesLoaded}
      />
      {imagesLoaded && (
        <div className="px-10 md:px-20 lg:px-32 flex flex-col gap-20">
          <div ref={servicesSectionRef}>
            <ServicesSection />
          </div>
          <ProcessSection />
          <FounderSection />
          <PortfolioSection />
          <div ref={submitFormRef}>
            <SubmitForm />
          </div>
          <Contact />
        </div>
      )}
    </div>
  );
}

function ServicesSection() {
  return (
    <div className="bg-white text-start mx-auto">
      <div className="flex flex-col text-center mx-auto py-16 md:py-20 lg:py-24 gap-8 md:gap-10 lg:gap-12">
        <h1 className="tr-3xl font-bold">What do we do?</h1>
        <p className="tr-lg max-w-3xl lg:max-w-6xl mx-auto">
          Design, Development, and marketing. We aim to solve all of your
          business needs. Have a look at our services and choose the ones you
          need to help you make your business thrive.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8 md:gap-10 lg:gap-12">
        <ServiceItem
          icon={abc1}
          title="Website Development"
          description="With years of experience, we've honed our skills in various web development technologies and frameworks. From front-end to back-end, we can handle it all. Our expertise spans HTML, CSS, JavaScript, React, Node.js, and more, ensuring high-quality, efficient solutions."
        />
        <ServiceItem
          icon={abc2}
          title="Mobile Development"
          description="We specialize in creating high-performance mobile applications for both iOS and Android platforms. Let us bring your app idea to life with seamless, user-friendly, and efficient apps. Our team is dedicated to delivering top-notch quality and performance for your mobile solutions."
        />
        <ServiceItem
          icon={abc3}
          title="Ecommerce Development"
          description="Need to set up an online store? We can help you build a secure and user-friendly e-commerce platform. Our team will ensure your store drives sales and boosts your business, providing a seamless shopping experience for your customers. Let us take your business to the next level."
        />
        <ServiceItem
          icon={abc4}
          title="UX/UI Planning"
          description="We start by understanding your users and their needs. We then create wireframes, prototypes, and final designs that are both functional and aesthetically pleasing. We also conduct usability testing to ensure that our designs are easy to use and understand."
        />
        <ServiceItem
          icon={abc5}
          title="Graphical Design"
          description="We offer a wide range of graphical design services, including logo design, branding, website design and social media graphics. We work with you to create designs that are consistent with your brand identity and that will help you achieve your marketing goals."
        />
        <ServiceItem
          icon={abc6}
          title="Marketing Services"
          description="Our marketing services include website development, search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, and email marketing. We work with you to develop a marketing strategy that is tailored to your specific needs and budget."
        />
      </div>
    </div>
  );
}

function ProcessSection() {
  return (
    <>
      <div className="text-center mx-auto">
        <h1 className="tr-3xl font-bold">
          Discover the Process of Working with Us
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8 md:gap-10 lg:gap-12">
        <ServiceItem
          icon={bbb1}
          title="Planning"
          description="We start by understanding your requirements and goals for the project. What do you need? What do you want to achieve? We'll work with you to define the scope of the project, set realistic deadlines and budgets. Together, we'll create a project plan that will help you achieve your goals."
        />
        <ServiceItem
          icon={bbb2}
          title="Development"
          description="We keep you updated throughout the development process. You'll always know where we are in the project and what's coming next. We'll share regular status updates, answer your questions, and get your feedback. You can be confident that your project is in good hands."
        />
        <ServiceItem
          icon={bbb3}
          title="Result"
          description="We ensure that your project is completed to your satisfaction. We'll work with you every step of the way to make sure that the project meets your needs and expectations. We're committed to delivering a project that you'll be proud of."
        />
      </div>
    </>
  );
}

function PortfolioSection() {
  return (
    <>
      <div className="text-start max-w-[850px]">
        <h3 className="tr-lg mb-4">Portfolio</h3>
        <h1 className="tr-3xl font-bold mb-4">
          Check out some of our happy clients!
        </h1>
        <p className="tr-base">
          Throughout the years we had the pleasure to work with some of the most
          interesting projects in the market. We humbly learned from their
          bright minds and gave our full dedication to make sure they get the
          best development service anyone can wish for.
        </p>
      </div>
      <PortfolioItem
        image={ccc1}
        logo={vvv3}
        title="Talkspace"
        description={
          <p>
            Talkspace is a leading online therapy platform that provides access
            to licensed therapists through chat, video, and phone sessions. The
            company is one of the unicorns in the mental health space, and has
            raised over $1.8 billion in funding. Talkspace offers a wide range
            of features, including chat therapy, video therapy, phone therapy,
            forms, HIPAA compliance, and dashboards.
            <br />
            <br /> We are proud of the work we did at Talkspace, and we believe
            that we made a positive impact on the company and its patients.
          </p>
        }
        buttonColor="bg-green-400"
        buttonLink="https://www.talkspace.com/"
        isColourBright={true}
      />
      <PortfolioItem
        image={ccc2}
        logo={vvv2}
        title="Jama"
        description={
          <p>
            Jama is Israel's leading AI-powered parenting platform, fostering a
            vibrant motherhood community. We contributed to Jama by integrating
            pivotal features such as map navigation, chat functionality, social
            media connectivity, infinite scrolling, and appointment scheduling.
            These enhancements have significantly enriched the user experience
            and app performance , making Jama a great social media platform for
            parents.
            <br />
            <br />
            We had the honour to work with the bright minds in Jama on a truely
            unique social media platform.
          </p>
        }
        buttonColor="bg-red-200"
        buttonLink="https://jama.co.il/"
        isColourBright={true}
      />
      <PortfolioItem
        image={ccc3}
        logo={vvv1}
        title="Omniverse"
        description={
          <p>
            Omniverse is a cutting-edge AdTech service, dedicated to optimizing
            the efficiency and cost-effectiveness of advertising campaigns. They
            harness AI and machine learning, to provide security against
            fraudulent ad activities, data science insights, and real-time
            adaptability. We played a pivotal role in enhancing their platform,
            integrating features like map functionalities, a demand-side
            platform akin to major corporations, and advanced AI capabilities.
            <br />
            <br />
            The project was truly inspiring as we had the opportunities to work
            on a special technological advancement in the industry
          </p>
        }
        buttonColor="bg-blue-500"
        buttonLink="https://www.omni-verse.ae/"
        isColourBright={false}
      />
    </>
  );
}

export default App;
