import Airtable from "airtable";

console.log({
  apiKey: process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN,
  baseId: process.env.REACT_APP_AIRTABLE_BASE_ID,
  tableName: process.env.REACT_APP_AIRTABLE_TABLE_NAME,
});

// Configure Airtable
const base = new Airtable({
  apiKey: process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN,
}).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

// Function to add a new user
export const addNewUser = async (userData) => {
  try {
    const result = await base(process.env.REACT_APP_AIRTABLE_TABLE_NAME).create(
      [
        {
          fields: {
            Name: userData.name,
            Email: userData.email,
            Phone: userData.phone,
            Message: userData.message,
            Status: "New Lead", // or whatever initial status you want
          },
        },
      ]
    );

    console.log("Added record", result[0].getId());
    return result[0];
  } catch (error) {
    console.error("Error adding record", error);
    throw error;
  }
};

export const checkUserExists = async (email, phone) => {
  try {
    const records = await base(process.env.REACT_APP_AIRTABLE_TABLE_NAME)
      .select({
        filterByFormula: `OR({Email} = '${email}', {Phone} = '${phone}')`,
      })
      .firstPage();
    return records.length > 0;
  } catch (error) {
    console.error("Error checking user existence", error);
    throw error;
  }
};
