import React from "react";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import ParticlesScreen from "./ParticlesScreen";
import Loader from "./Loader";
import logo from "../assets/logo.png";

const HeroSection = ({ onContactUsClick, onLearnMoreClick, imagesLoaded }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.03,
      },
    },
  };

  const logoVariants = {
    hidden: { opacity: 0, rotate: 1080, scale: 0 },
    visible: {
      opacity: 1,
      rotate: 0,
      scale: 1,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 100,
        duration: 4,
      },
    },
  };

  const AnimatedText = ({ text, className, delayGap }) => {
    let totalCharacters = 0;

    return (
      <p className={className}>
        {text.split(" ").map((word, wordIndex, wordsArray) => {
          const wordStart = totalCharacters;
          totalCharacters +=
            word.length + (wordIndex < wordsArray.length - 1 ? 1 : 0);

          return (
            <span
              key={wordIndex}
              style={{ display: "inline-block", whiteSpace: "nowrap" }}
            >
              {word.split("").map((char, charIndex) => (
                <motion.span
                  key={charIndex}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.5 + (wordStart + charIndex) * delayGap,
                    type: "spring",
                    damping: 12,
                    stiffness: 200,
                  }}
                  style={{ display: "inline-block" }}
                >
                  {char}
                </motion.span>
              ))}
              {wordIndex < wordsArray.length - 1 ? "\u00A0" : ""}
            </span>
          );
        })}
      </p>
    );
  };

  return (
    <motion.div
      className="bg-black text-white p-4 sm:p-6 md:p-10 min-h-screen flex justify-center relative"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="z-10">
        <ParticlesScreen />
      </div>
      <AnimatePresence>{!imagesLoaded && <Loader />}</AnimatePresence>
      {imagesLoaded && (
        <div className="z-20 flex items-center gap-12 md:gap-6 flex-col md:flex-row justify-center lg:justify-center w-full  mx-auto">
          <motion.div variants={logoVariants} className="w-80 3xl:w-[24rem]">
            <img src={logo} alt="Logo" className="w-full h-auto" />
          </motion.div>
          <div className="px-4  text-center md:text-left flex flex-col lg:gap-0 gap-4 max-w-[50%]">
            <AnimatedText
              text="Software that Wins"
              className="tr-4xl font-bold "
              delayGap={0.06}
            />

            <AnimatedText
              text="At Patternz, we create cutting-edge custom software solutions tailored to your needs. Our dedicated expert team is trusted by top companies to deliver outstanding results and drive your success"
              className="tr-lg my-3 sm:my-4 md:my-5 "
              delayGap={0.02}
            />
            <div className="space-x-2 sm:space-x-4 flex justify-center md:justify-start">
              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7, duration: 0.5 }}
                onClick={onContactUsClick}
                className="tr-base bg-yellow-500 text-black px-2 sm:px-4 md:px-6 py-2 rounded-md font-semibold hover:bg-black hover:text-yellow-500 border border-yellow-500 transition-colors"
              >
                Contact us
              </motion.button>
              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 0.5 }}
                onClick={onLearnMoreClick}
                className="tr-base border border-white-500 px-2 sm:px-4 md:px-6 py-2 rounded-md font-semibold hover:bg-white hover:text-black transition-colors"
              >
                Learn more
              </motion.button>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default HeroSection;
