import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import { itemVariants } from "../SharedConsts";

function ServiceItem({ icon, title, description }) {
  return (
    <motion.div
      className="w-full"
      initial="hidden"
      whileInView="visible"
      exit="exit"
      viewport={{ once: false, amount: 0.1 }}
      variants={itemVariants}
      transition={{ duration: 1 }}
    >
      <Tilt
        className="Tilt h-full"
        options={{
          max: 25,
          scale: 1.05,
          speed: 500,
          transition: true,
          easing: "cubic-bezier(.03,.98,.52,.99)",
          perspective: 1000,
        }}
      >
        <div className="bg-white rounded-lg text-justify relative z-10 h-full p-6 md:p-8 flex flex-col">
          <img
            src={icon}
            alt={title}
            className="w-full h-auto mb-6 md:mb-8 mx-auto object-contain"
          />
          <h2 className="tr-base font-bold text-black mb-4 md:mb-6">{title}</h2>
          <p className="tr-sm text-gray-600 flex-grow">{description}</p>
        </div>
      </Tilt>
    </motion.div>
  );
}

export default ServiceItem;
