import React, { useRef, useEffect, useState } from "react";

const CanvasParticles = () => {
  const canvasRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let animationFrameId;

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Assuming mobile devices are 768px or less
    };

    const resizeCanvas = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      checkMobile();
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    class Particle {
      constructor() {
        this.reset();
      }

      reset() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 3 + 1;
        this.speedX = (Math.random() - 0.5) * 0.5;
        this.speedY = (Math.random() - 0.5) * 0.5;
        this.opacity = 0;
        this.fadeInOut = Math.random() * 0.001 * (Math.random() < 0.5 ? 1 : -1);
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.x < 0 || this.x > canvas.width) this.speedX *= -1;
        if (this.y < 0 || this.y > canvas.height) this.speedY *= -1;

        this.opacity += this.fadeInOut;
        if (this.opacity > 0.25 || this.opacity < 0) {
          this.fadeInOut *= -1;
        }

        if (this.opacity <= 0) {
          this.reset();
        }
      }

      draw() {
        ctx.fillStyle = `rgba(234, 179, 8, ${this.opacity})`;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size * 16, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    const particleCount = isMobile ? 40 : 100; // Reduce to 1/4 on mobile
    const particles = Array.from(
      { length: particleCount },
      () => new Particle()
    );

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((particle) => {
        particle.update();
        particle.draw();
      });
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener("resize", resizeCanvas);
    };
  }, [isMobile]);

  return (
    <canvas
      ref={canvasRef}
      className="absolute h-full w-full inset-0 bg-black"
    />
  );
};

export default CanvasParticles;
